<!--  -->
<template>
    <div>
        <TabTopVue :top_title="top_title" :top_title1="top_title1" :buttonshow="false" :type="3"></TabTopVue>
        <SeverePageVue :newdata="newdata1" :buttontitle="'sell_house'" :type="3"></SeverePageVue>
        <WeDoVue></WeDoVue>
        <SeverePageVue :newdata="newdata2" :buttontitle="'sell_house'" :type="4"></SeverePageVue>
        <SeverePageVue :newdata="newdata3" :buttontitle="'sell_house'" :type="5"></SeverePageVue>
        <!-- <keep-alive>
        <component :is="currentComponent" :houseid="houseid"></component>
      </keep-alive> -->
        <div class="tablist">


        </div>
    </div>
</template>

<script>
import TabTopVue from '../components/TabTop.vue'
// import WeDoVue
// import MainServices from '@/components/MainServices.vue';
// import HouseList from '@/components/HouseList.vue';
// import NewList from '@/components/NewList.vue';
// import { houseList, banner, imgText, renthouseList } from '../request/api'
// import TitlePageVue from '@/components/TitlePage.vue';
// import HomePageVue from '@/components/HomePage.vue';
import SeverePageVue from '../components/SeverePage.vue';
import WeDoVue from '@/components/WeDo.vue';

// import HouseDetailVue from '@/components/HouseDetail.vue';

export default {
    components: {
  
        WeDoVue,
        TabTopVue,
        SeverePageVue,

    },
    name: 'TabThree',
    data() {

        return {
            newdata1: {
                title: 'Who We Are',
                intro: 'Unbound AI is a leading provider of advanced computational modeling and AI-driven solutions, specializing in the fields of materials science, engineering, and life sciences. Our multidisciplinary team of experts combines deep technical knowledge with innovative thinking to deliver tailored solutions that meet the unique needs of our clients.'
            },
            newdata2: {
                title: 'Our Vision',
                intro: 'We envision a future where scientific discovery and technological innovation are accelerated by the seamless integration of advanced simulations and AI-driven insights. Our goal is to be the preferred partner for organizations seeking to overcome their most significant challenges through the power of data and intelligent modeling.'
            }, newdata3: {
                title: 'Our Team',
                intro: 'Our team is composed of experts from around the globe, each bringing a wealth of knowledge and experience from the world is leading universities and institutions. Our diverse group includes PhDs, postdoctoral researchers, and scientists, all educated in English-speaking countries renowned for their academic excellence. In addition, we are proud to have world-class product designers and engineers who are at the forefront of innovation in their respective fields. Together, this team of exceptional talent and expertise drives Unbound AI’s mission to deliver cutting-edge solutions and groundbreaking advancements across multiple industries.'
            },
            top_title: 'Our Mission',
            top_title1: 'At Unbound AI, our mission is to harness the power of advanced simulations, data analytics, and artificial intelligence to drive innovation and solve complex challenges in engineering and life sciences.',
            house_stats: '',
            currentComponent: 'HomePageVue',
            page: 1,
            houseid: 0,
            tabtype: 0,
            tabtype1: 0,
            formInline: {
                name: '',
                Search_location: '',
                Type: '',
                Min_bedrooms: '',
                Min_Price: '',
                MinMax_Price: [0, 10000],
                Max_Price: '',
                Exclude_sold_properties: '',
                SEARCH_PROPERTIES: '',
                Distance: '',
                pricetype: '',
                location: { lat: 0, lng: 0 },
                house_type_key: '',
                sell_type: "", //售卖分类ID
                rent_type_id: "", //租房分类id
                radio: 1,
                is_sell: '',
                is_collect: 0
            },
            location: { lat: 0, lng: 0 },
            list: [

            ],
            radio: 0,
            severlist: [
                {
                    image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
                    title1: 'Trust in the value of experience',
                    title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
                    title3: 'SERVICES WE OFFER',

                },
                {
                    image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
                    title1: 'Safest pair of hands in Surrey',
                    title2: 'We are experts at managing the sales process to ensure a successful completion and our team all live in the local area. When you work with us you are backed by a team of highly skilled market leading agents. We offer a complete range of property services and have chartered planning consultants and surveyors under one roof.',
                    title3: 'READ OUR REVIEWS',

                },
                {
                    image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
                    title1: 'Trust in the value of experience',
                    title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
                    title3: 'SERVICES WE OFFER',

                },
                {
                    image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
                    title1: 'Trust in the value of experience',
                    title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
                    title3: 'SERVICES WE OFFER',

                },
            ],
            our_service: {},
            contact_us: {},
            about_us: {},
            our_team: {},
            join_team: {},
            tenement: {},

            buy_house: {},
            rent_house: {},
            flat: {},
            fitment: {},
            sell_house: {},
            loan: {},
            team_member: {},
        }
    },
    computed: {



        getformInline() {
            return this.$store.state.formInline;
        },

    },
    watch: {

        getformInline: {
            handler: function (newValue, oldValue) {
                // 当nestedData或其内部属性变化时，会调用这个函数
                console.log('oldValue', oldValue)
                console.log('newValue', newValue)
                this.page = 1
                this.getdata()
                // this.$store.commit('updateformInline', newValue)
            },
            deep: true // 开启深度监听
        }

    },
    methods: {
        // tabclick(item, index) {
        //   this.tabtype = index
        // },
        // tabclick1(item, index) {
        //   this.tabtype1 = index
        // },
        getpage(data) {
            console.log('父组件接收到的数据：' + data)
            if (data) {
                this.page = this.page + 1
                console.log('that.getformInline.sell_type', this.getformInline.sell_type)
                this.getdata()
            }

        },
        getHouseId(houseid, house_stats) {
            console.log('父组件接收到的数据：' + houseid)
            this.houseid = houseid
            this.house_stats = house_stats
            this.$router.push({ name: 'HouseDetail', params: { houseid: this.houseid, house_stats: this.house_stats } })
        },
        initMap() {
            var that = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        that.location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        console.log('location', that.location)
                        if (that.location) {
                            // that.getdata()
                        }
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        },

        async getdata() {
            // var that = this
            // console.log('getformInline', this.getformInline)
            // if (that.getformInline.sell_type == 2) {
            //   console.log('hhhhhhhhhhhhhhhhhhhhhhhhhh')
            //   await renthouseList({
            //     limit: 10, //每页展示的条数
            //     page: this.page, //页码
            //     lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
            //     lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
            //     distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
            //     house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
            //     house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
            //     house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
            //     house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
            //     house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
            //     rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
            //     sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
            //     is_sell: that.getformInline.is_sell ? Number() : '', //售价
            //     house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
            //     is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : '',//排序方式ID
            //     min_week_rent: that.getformInline.min_week_rent ? that.getformInline.min_week_rent : '', //周租金最低金额
            //     max_week_rent: that.getformInline.max_week_rent ? that.getformInline.max_week_rent : '', //周租金最高金额
            //     min_month_rent: that.getformInline.min_month_rent ? that.getformInline.min_month_rent : '', //月租金最低金额
            //     max_month_rent: that.getformInline.max_month_rent ? that.getformInline.max_month_rent : '', //月租金最高金额
            //     min_available_date: that.getformInline.min_available_date ? that.getformInline.min_available_date : '', //最小入驻日期
            //     max_available_date: that.getformInline.max_available_date ? that.getformInline.max_available_date : '',//最大入驻日期
            //   }).then(res => {
            //     // this.list = res.data.house_list
            //     // console.log(this.list)
            //     var pagelist = []
            //     pagelist = res.data.house_list
            //     if (this.page == 1) {
            //       this.list = pagelist
            //     }
            //     else {
            //       this.list = this.list.concat(pagelist)
            //     }
            //     // this.$message({
            //     //   message: '房源已更新',
            //     //   type: 'success'
            //     // });
            //   })

            // }
            // else {
            //   console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')


            //   await houseList({
            //     limit: 10, //每页展示的条数
            //     page: this.page, //页码
            //     lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
            //     lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
            //     distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
            //     house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
            //     house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
            //     house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
            //     house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
            //     house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
            //     rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
            //     sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : 1, //售价
            //     is_sell: that.getformInline.is_sell ? Number() : '', //售价
            //     house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
            //     is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : ''//排序方式ID
            //   }).then(res => {
            //     // this.list = res.data.house_list
            //     // console.log(this.list)
            //     var pagelist = []
            //     pagelist = res.data.house_list
            //     if (this.page == 1) {
            //       this.list = pagelist
            //     }
            //     else {
            //       this.list = this.list.concat(pagelist)
            //     }
            //     // this.$message({
            //     //   message: '房源已更新',
            //     //   type: 'success'
            //     // });
            //   })

            // } console.log('getformInline', this.getformInline)



        },
        // async getdata1() {
        //   var that = this


        //   console.log('getformInline', this.getformInline)
        //   if (that.getformInline.sell_type == 2) {
        //     await renthouseList({
        //       limit: 10, //每页展示的条数
        //       page: this.page, //页码
        //       lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
        //       lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
        //       distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
        //       house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
        //       house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
        //       house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
        //       house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
        //       house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
        //       rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
        //       sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
        //       is_sell: that.getformInline.is_sell ? Number() : '', //售价
        //       house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
        //       is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : '',//排序方式ID
        //       min_week_rent: that.getformInline.min_week_rent ? that.getformInline.min_week_rent : '', //周租金最低金额
        //       max_week_rent: that.getformInline.max_week_rent ? that.getformInline.max_week_rent : '', //周租金最高金额
        //       min_month_rent: that.getformInline.min_month_rent ? that.getformInline.min_month_rent : '', //月租金最低金额
        //       max_month_rent: that.getformInline.max_month_rent ? that.getformInline.max_month_rent : '', //月租金最高金额
        //       min_available_date: that.getformInline.min_available_date ? that.getformInline.min_available_date : '', //最小入驻日期
        //       max_available_date: that.getformInline.max_available_date ? that.getformInline.max_available_date : '',//最大入驻日期
        //     }).then(res => {
        //       // this.list = res.data.house_list
        //       // console.log(this.list)
        //       var pagelist = []
        //       pagelist = res.data.house_list
        //       if (this.page == 1) {
        //         this.list = pagelist
        //       }
        //       else {
        //         this.list = this.list.concat(pagelist)
        //       }
        //       // this.$message({
        //       //   message: '房源已更新',
        //       //   type: 'success'
        //       // });
        //     })

        //   }
        //   else {

        //     await houseList({
        //       limit: 10, //每页展示的条数
        //       page: this.page, //页码
        //       lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
        //       lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
        //       distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
        //       house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
        //       house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
        //       house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
        //       house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
        //       house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
        //       rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
        //       sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : 1, //售价
        //       is_sell: that.getformInline.is_sell ? Number() : '', //售价
        //       house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
        //       is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : ''//排序方式ID
        //     }).then(res => {
        //       // this.list = res.data.house_list
        //       // console.log(this.list)
        //       var pagelist = []
        //       pagelist = res.data.house_list
        //       if (this.page == 1) {
        //         this.list = pagelist
        //       }
        //       else {
        //         this.list = this.list.concat(pagelist)
        //       }
        //       // this.$message({
        //       //   message: '房源已更新',
        //       //   type: 'success'
        //       // });
        //     })

        //   }

        // },
        async getimgtext() {
            // await imgText().then(res => {
            //   if (res.data) {
            //     this.our_service = res.data.our_service
            //     this.contact_us = res.data.contact_us
            //     this.about_us = res.data.about_us
            //     this.our_team = res.data.our_team
            //     this.join_team = res.data.join_team
            //     this.tenement = res.data.tenement
            //     this.buy_house = res.data.buy_house
            //     this.rent_house = res.data.rent_house
            //     this.flat = res.data.flat
            //     this.fitment = res.data.fitment
            //     this.sell_house = res.data.sell_house
            //     this.loan = res.data.loan
            //     this.team_member = res.data.team_member



            //   }

            // })
        },

    },
    async created() {
        // this.initMap()
        // this.getimgtext()

        // await banner({
        //   type: 1
        // }).then(res => {

        //   console.log('res', res)

        // })
        // this.getdata1()
        // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    }
}
</script>
<style scoped>
/* 针对移动端的样式 */

/* 针对桌面端的样式 */

.tablist {
    /* margin:20px 10vw */
}



.tabbg {
    width: 1920px;
    height: auto;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
}

.searchbox {
    top: 110px;
    left: 471px;
    width: 978px;
    height: 379px;
    background: rgba(0, 0, 0, 0.85);
    margin: 0 auto;
    /* margin-top: -230px; */
    position: absolute;

}

.searchbox1 {
    margin: 44px auto;
    width: 100%;
    text-align: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 16px;
    color: #51B1FF;
    line-height: 16px;
    text-align: left;
}

.blue {
    color: #51B1FF;
}

.write {
    color: #fff;

}

.searchinput {
    width: 700px;
}

.searchselect0 {
    width: 100px;
}

.searchselect {
    width: 193px;
}



.searchtabs {
    margin-bottom: 10px;
    justify-content: center;
}

.searchform {
    width: 84%;
    margin: 0 auto;
}

.searchtab {
    padding: 18px;
}

.searchbtns {
    text-align: center;
}

.searchbtn {
    min-width: 100px;
}

.tablist_t {
    margin: 20px 0;

    justify-content: space-between;
}

.w24 {
    width: 24px;
    height: 24px;
}

.botton1 {
    padding: 10px 16px;
    text-align: center;
    margin-right: 20px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 22px;
    color: #111111;
    line-height: 48px;

    font-style: normal;
    text-transform: none;
}

.severbox {
    margin-top: 120px;
}
</style>