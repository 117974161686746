import Vue from 'vue'
import App from './App.vue'
// import axios from 'axios'//引入
// Vue.prototype.$http = axios //正确的使用
Vue.config.productionTip = false

// import getData from "../network/index"
import axios from 'axios'//引入
Vue.prototype.$axios = axios;
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.config.productionTip = false
// 导入i18n
import i18n from './i18n/i18n';
import store from './store';
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
Vue.use(ElementUI) //使用elementUI
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './Router/router';
Vue.use(Vant);
import './utils/rem'
Vue.config.productionTip = false
// import VueRouter from 'vue-router'


// Vue.use(VueRouter)

// const router = new VueRouter({
//   mode: 'history',
//   routes: routers
// })

//配置Vue插件 将El安装到Vue上
//时间戳转化日期格式
Vue.filter('formatDate', function (value) {
  if (!value) return '';
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
 
  return `${year}-${month}-${day}`;
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7bOvvuilzENsjRbgjYlXvNMAIvQpPyws', // 此处填入谷歌地图申请的key
    libraries: 'places'
  }
})
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
