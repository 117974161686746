<!--  -->
<template>
    <div>
        <div class="servicesbox">
            <div class="seve_top">
                What We Do
            </div>
            <div class="seve_top1">
                We offer a comprehensive range of services designed to tackle the most challenging problems in our target industries
            </div>
            <div class="seve_boxs">
                <div class="seve_box " v-for="(item, index) in list" :key="index">
                    <div class="seve_box_l" v-if="index==0">
                        <img src="../static/index/s1.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index==1">
                        <img src="../static/index/s2.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index==2">
                        <img src="../static/index/s3.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index==3">
                        <img src="../static/index/s4.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_r">
                        <div class="r_title1">
                            {{ item.title }}
                        </div>
                        <div class="r_title2">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img src="../static/index/s1.png" alt=""> -->

            <div class="togo flex1" @click="togo()">
                LEARN MORE
                <i class="el-icon-right ml10"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                icon: '../static/index/s1.png',
                title: 'Data Science Solutions',
                content: ' Our expertise in big data analytics allows us to uncover actionable insights, optimize processes, and provide data-driven decision support across multiple domains. '
            }, {
                icon: '../static/index/s2.png',
                title: 'AI in Life Sciences',
                content: 'Our AI-powered solutions are transforming the life sciences, from drug discovery to personalized medicine and disease progression prediction, providing critical insights that enhance patient care.'
            }, {
                icon: '../static/index/s3.png',
                title: 'Metamaterial Structural Design',
                content: 'Leveraging advanced computational tools, we design and optimize metamaterial structures with novel properties, driving breakthroughs in various sectors.'
            }, {
                icon: '../static/index/s4.png',
                title: 'Finite Element Analysis (FEA) and Molecular Dynamics Simulations',
                content: 'We develop precise simulation models for alloy materials, cement-based materials, and non-metallic materials, enabling our clients to predict material behaviors, optimize designs, and accelerate product development.'
            }]
        }
    },
    methods:{
        togo() {
            this.$emit('TabEvent', this.$t('Tab')[1])
            this.$store.commit('updatetypetab', this.$t('Tab')[1])
        }
    }
}

</script>

<style scoped>
.servicesbox {
    /* padding: 90px 380px; */
    text-align: center;
    background: #101112;
    padding-bottom: 30px;
}

.seve_top {
    /* width: 257px; */
    margin-top: 20px;
    margin-bottom: 23px;
    height: 53px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 53px;

}

.seve_top1 {
    /* width: 257px; */
    /* margin-top: 80px; */
    margin-bottom: 23px;
    /* width: 320px; */
    height: 16px;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #9E9E9E;
    line-height: 16px;


}

.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}
.seve_boxs {
    width: 75%;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin: 20px auto;

}

.seve_box {
    /* width: 580px; */
    /* height: 172px; */
    cursor: pointer;
    text-align: center;
    background: #1D2023;
    padding: 16px;
    border-radius: 10px;
}

.seve_box:hover {
    /* width: 580px; */
    /* height: 172px; */
    cursor: pointer;
    border: 2px solid  rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    text-align: center;
    background: #1D2023;
    padding: 16px;
    border-radius: 10px;
}


.seve_box_l {
    /* width: 80px;
    height: 80px;
    line-height:100px;
    margin-right: 25px;
    background: rgba(38, 208, 255, 0.1);
    border-radius: 8px 8px 8px 8px; */

}
.seve_box_r {
    /* width: 80%; */
 
}

.r_title1 {
margin: 25px auto;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 20px;
/* text-align: left; */
}

.r_title2 {

    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 24px;
    /* padding-bottom: 30px; */
/* text-align: left; */


}
</style>
