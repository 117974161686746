<template>
    <div class="bottom1s">
        <div class="bottom1 ">
            <div class="bottom11 ">
                {{ $t('bottom.tab1') }}
                <div class="text flex1" v-for="(item, index) in $t('Tab')" :key="index" @click="onClick(item)">
                    <div class="name">{{ item }}</div>
                </div>
            </div>

            <div class="bottom13 ">
                {{ $t('bottom.tab2') }}
                <div class="text flex1">
                    <!-- {{ $t('bottom.name') }}: -->
                    <!-- <div class="name">{{ room.company.address }}</div> -->
                    <div class="name">Unbound AI Ltd 54 Southwark Bridge Road SE1 0AR</div>

                </div>
                <div class="text flex1">
                    <!-- {{ $t('bottom.tel') }}: -->
                    <!-- <div class="name">T {{ room.webFooterPhone }}</div> -->
                    T
                    <div class="name" style=" text-decoration-line: underline;cursor: pointer;"
                        @click="copy('020 7261 1234')"> 020 7261 1234</div>
                </div>
                <div class="text flex1">
                    <!-- {{ $t('bottom.email') }}: -->
                    <!-- <div class="name">E {{ room.webFooterEmail }}</div> -->
                    E
                    <div class="name" style=" text-decoration-line: underline;cursor: pointer;padding-bottom: 10px;"
                        @click="copy('londonbridge@Unbound AI.com')"> londonbridge@Unbound AI.com</div>
                </div>

            </div>
            <div class="bottom12  flex1">
                {{ $t('bottom.code') }}:
                <!-- <div class="name">{{ room.webFooterRegisterCode }}</div> -->
                <div class="name"> 11955090</div>
            </div>

        </div>



    </div>
</template>

<script>
// import { webConfig } from '../request/api'
export default {
    name: 'bottomVue',
    data() {
        return {
            refresh: this.$t('refresh'), // 第三种用法，不过这种用法在切换预言时，需要刷新页面才会生效
            dialogVisible: false,
            // languageVal: this.$i18n.locale,
            options: [{
                value: 'cn',
                label: '中文'
            }, {
                value: 'en',
                label: 'English'
            }],
            tabtype: this.$t('bottom.tabs1')[0],
            activeName: this.$t('Tab')[0],
            active: 0,
            code: "9999999",
            room: {}
        }
    },
    methods: {
        copy(data) {
            const input = document.createElement('input');
            input.value = data; // 设置要复制的文本
            document.body.appendChild(input); // 添加input元素
            input.select(); // 选中文本
            document.execCommand('copy'); // 执行复制操作
            document.body.removeChild(input); // 移除input元素
            this.$message({
                message: '复制成功',
                type: 'success'
            });
            // alert('文本已复制'); // 可选：弹窗提示复制成功
        },
        langChange(e) {
            console.log(e)
            localStorage.setItem('lang', e);
            this.$i18n.locale = e;
            //   window.location.reload() // 第三种用法刷新页面
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        onClick(title) {
            console.log(title)
            // Toast(title);
            this.tabtype = title
            this.$emit('TabEvent', this.tabtype)
            this.$store.commit('updatetypetab', title)
            // 获取到前面打过标签的元素，将其scrollTop属性设置为0
            document.documentElement.scrollTop = 0
            // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
            document.body.scrollTop = 0

        },
        async webConfig() {


            // await webConfig().then(res => {
            //     console.log('companycompanycompanycompanycompany', res)
            //     this.room = res.data

            // })
        },


    },
    created() {
        console.log(this.tabtype, ' this.tabtype this.tabtype this.tabtype')
        this.$emit('TabEvent', this.tabtype)
        // this.webConfig()
    },


}
</script>
<style scoped>
/* 针对桌面端的样式 */

.bottom1 {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 60px 100px;
    flex-wrap: wrap;

}

.bottom1s {
    width: 100%;
    justify-content: space-between;
    /* height: 347px; */
    /* align-items: start; */
    background: #131A2C;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #D6D6D6;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    border-radius: 0px 0px 0px 0px;

}


.bottom12 {
    align-items: center;
}

.text {
    margin-top: 20px;
    font-size: 16px;
    /* align-items: center; */
}

.name {
    cursor: pointer;
    /* font-family: AlibabaPuHuiTi, AlibabaPuHuiTi; */
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #9E9E9E;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 10px;
}

.bottom11 {
    width: 25%;
}

.bottom13 {
    width: 45%;
}
</style>
