<!--  -->
<template>
    <div class="tabtop">
        <img src="../static/index/tabbg2.png" class="tabbg" alt="">
        <div class="casebox">
            <div class="case_boxs_title">
                <div>Unbound AI offers the following core services</div>
            </div>
            <div class="case_boxs">
                <div class="case_box " v-for="(item, index) in list" :key="index">
                    <div class="case_box_l" v-if="index == 0">
                        <img src="../static/index/s1.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="case_box_l" v-if="index == 1">
                        <img src="../static/index/s5.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="case_box_l" v-if="index == 2">
                        <img src="../static/index/s6.png" style="width: 40px;height: 40px;" alt="">
                    </div>

                    <div class="case_box_r">
                        <div class="r_title1">
                            {{ item.title }}
                        </div>
                        <div class="r_title2">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                icon: '../static/index/s1.png',
                title: 'AI Algorithm Development and Data Science Consulting',
                content: 'We provide customized AI algorithm development and data science consulting services for enterprises and research institutions'
            }, {
                icon: '../static/index/s2.png',
                title: 'Smart Manufacturing and Industrial IoT Solutions',
                content: 'Through advanced big data analytics and AI technologies, we help companies optimize production processes, enhance efficiency and quality'
            }, {
                icon: '../static/index/s3.png',
                title: 'Scientific and Industrial Software Integration',
                content: 'We specialize in integrating advanced scientific and industrial software (such as LAMMPS, COMSOL, Abaqus, etc.) with AI technology'
            }],
            formInline: {
                user: '',
                region: ''
            }
        }
    }
    , methods: {


        async getdata() {
            // await ourShow({


            //     page: this.page, //页码

            //     limit: 12 //每页显示条数
            // }).then(res => {

            //     var pagelist = []
            //     pagelist = res.data.news_list
            //     if (this.page == 1) {
            //         this.contactlist = pagelist
            //     }
            //     else {
            //         this.contactlist = this.list.concat(pagelist)
            //     }


            // })
        },
        onClick(name, title) {

            this.tabtype = title
            this.$emit('HouseId', this.houseid)
        },

    }
}

</script>

<style scoped>
.tabtop {
    position: relative;
    height: 750px;
}

.tabbg {
    width: 100%;
    height: 750px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
}

.casebox {
    text-align: center;
    margin: 170px auto;
    /* background: rgba(0, 0, 0, 0.85); */
    /* margin: 0 auto; */

    /* margin-top: -230px; */
    position: absolute;

}

.searchbox1 {
    margin: 44px auto;
    width: 100%;
    text-align: center;
}

.case_boxs_title {
    width: 100%;
    /* margin: 44px 300px; */
    text-align: center;
    height: 93px;

    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 36px;
    color: #26D0FF;
    line-height: 64px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}

.top_title1 {
    /* width: 989px; */
    height: 70px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 70px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}

.case_boxs {
    width: 75%;
    display: grid;
    /* text-align: center; */
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin: 20px auto;

}

.case_box {
    border-radius: 10px;
    cursor: pointer;
    background: rgba(29, 32, 35, 0.8);
    /* border: 1px solid rgba(255, 255, 255, 0.4000000059604645); */

    /* border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.4000000059604645), rgba(0, 0, 0, 0)) 1 1; */
    padding: 32px;
    padding-bottom: 64px;
}

.case_box:hover {
    border-radius: 10px;
    cursor: pointer;
    background: rgba(29, 32, 35, 0.8);
    border: 1px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);

    /* border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.4000000059604645), rgba(0, 0, 0, 0)) 1 1; */
    padding: 32px;
    padding-bottom: 64px;
}

.case_box_l {
    text-align: left;
    /* width: 80px;
    text-align: left;
    height: 80px;
    line-height:100px;
    margin-right: 25px;
    background: rgba(38, 208, 255, 0.1);
    border-radius: 8px 8px 8px 8px; */

}

.case_box_r {
    /* width: 80%; */

}

.r_title1 {

    margin: 20px auto;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: left;

}

.r_title2 {


    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 24px;
    text-align: left;




}
</style>
