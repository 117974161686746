// 首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from '@/request/request'

//我们的展示
export function ourShow() {
  return request({
    method: 'POST',
    url: '/api/index/ourShow'
  })
}

//联系我们
export function contactUs(data) {
  return request({
    method: 'POST',
    url: "/api/index/contactUs",
    data: data
  })
}





