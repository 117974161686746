<!--  -->
<template>
    <div>

        <div class="severe">
            <div class="severboxs">
                <div class="sb11">
                    <div class="flex-between sb1  flex1">

                        <div class="sb_r">
                            <div class="sb_r_t">

                                {{ newdata.title }}
                            </div>
                            <div class="sb_r_m">

                                {{ newdata.intro }}
                            </div>
                            <div class="sb_r_call flex1">
                                <div class="call_img">
                                    <img src="../static/index/call.png" class="call_img1" alt="">
                                </div>
                                <div>
                                    020 7261 1234
                                </div>
                            </div>
                            <div class="sb_r_email flex1">
                                <div class="email_img">
                                    <img src="../static/index/email.png" class="email_img1" alt="">
                                </div>
                                <div>
                                    londonbridge@Unbound AI.com
                                </div>
                            </div>
                        </div>
                        <div class="sb_l p20">
                            <div class=" d_input">
                                <div class="d_input_input mtb15">
                                    <div class="d_input_input_title">Name</div>
                                    <el-input v-model="enquiry.Name" placeholder="Full name"
                                        class="input_input3"></el-input>
                                </div>
                                <div class="d_input_input mtb15">
                                    <div class="d_input_input_title">Email</div>

                                    <el-input v-model="enquiry.Email" placeholder="example@yourmail.com"
                                        class="input_input3"></el-input>
                                </div>
                                <div class="d_input_input mtb15">
                                    <div class="d_input_input_title">Phone</div>
                                    <el-input v-model="enquiry.Phone" placeholder="Phone"
                                        class="input_input3"></el-input>
                                </div>
                                <div class="d_input_input mtb15">
                                    <div class="d_input_input_title">Subject</div>

                                    <div class="d_input_input1">
                                        <el-input v-model="enquiry.Subject" placeholder="Subject"
                                            class="input_input3"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div class=" d_input1">
                                <div class="d_input_input_title">Message</div>
                                <div class="d_input_input">
                                    <el-input v-model="enquiry.Message" placeholder="Message"
                                        class="input_input2"></el-input>
                                </div>
                            </div>
                            <div class="togo flex1" @click="putenquiry1">
                                Submit
                                <i class="el-icon-right ml10"></i>
                            </div>

                        </div>
                    </div>

                </div>


            </div>



        </div>
    </div>
</template>

<script>
import { contactUs } from '../request/api'

export default {

    props: {

        newdata: Object,
        buttontitle: String
    },
    data() {
        return {
            enquiry: {
                Name: '',
                Email: '',
                Phone: '',
                Subject: '',
                Message: ''
            },
            screenWidth: document.body.clientWidth,//初始化宽度
            houseid: 0,

            tabtype: 0,
            tabtype1: 0,
            list: [],
            radio: 0,
            severlist: {},
            buttontile1: '',
            buttonpath: '',
        }
    },
    async created() {
        // this.getdata()
        //     our_service：我们的服务
        // contact_us：联系我们
        // about_us：关于我们
        // our_team：我们的团队
        // join_team：加入团队
        // tenement：物业建议
        // buy_house：我要买房
        // rent_house：我要租房
        // flat：服务公寓/短租
        // fitment：装修改建
        // sell_house：卖房估价
        // loan：贷款建议
        // team_member：团队人员

        console.log(this.buttontitle, 'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        if (this.buttontitle == 'our_service') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'SERVICES WE OFFER' : '我们的服务'
            this.buttonpath = this.$t('Tab')[3]
            // this.$emit('TabEvent', this.tabtype)
            // this.$store.commit('updatetypetab',  this.tabtype)
        }
        else if (this.buttontitle == 'contact_us') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'READ OUR REVIEWS' : '联系我们'
            this.buttonpath = this.$t('Tab1')[3]

        }
        else if (this.buttontitle == 'about_us') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'FIND YOUR BRANCH' : '关于我们'
            this.buttonpath = this.$t('Tab1')[0]

        }
        else if (this.buttontitle == 'our_team') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'MEET OUR TEAM' : '团队人员'
            this.buttonpath = this.$t('Tab1')[1]

        }
        else if (this.buttontitle == 'join_team') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'JOIN OUR TEAM' : '加入我们'
            this.buttonpath = this.$t('Tab1')[1]

        }
        else if (this.buttontitle == 'tenement') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'tenement' : '物业建议'
            this.buttonpath = this.$t('Tab')[4]

        }
        else if (this.buttontitle == 'buy_house') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'Buy House' : '我要买房'
            this.buttonpath = this.$t('Tab')[1]

        }
        else if (this.buttontitle == 'rent_house') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'Rent House' : '我要租房'
            this.buttonpath = this.$t('Tab')[2]

        }
        else if (this.buttontitle == 'flat') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '服务公寓/短租'
            this.buttonpath = this.$t('Tab')[3]

        }
        else if (this.buttontitle == 'fitment') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '装修改建'
            this.buttonpath = this.$t('Tab')[4]

        }
        else if (this.buttontitle == 'sell_house') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'BOOK A VALUATION' : '预约评估您待出售的房产'
            this.buttonpath = this.$t('Tab')[5]

        }
        else if (this.buttontitle == 'loan') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '贷款建议'
            this.buttonpath = this.$t('Tab')[6]

        }
        else if (this.buttontitle == 'team_member') {
            this.buttontile1 = this.$i18n.locale == 'en' ? 'SPEAK TO AN EXPERT' : '团队人员'
            this.buttonpath = this.$t('Tab1')[1]

        }

    },

    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            if (!this.timer) {
                // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                this.screenWidth = val
                this.timer = true
                let that = this
                setTimeout(function () {
                    // 打印screenWidth变化的值
                    console.log(that.screenWidth)
                    that.timer = false
                }, 400)
            }
        },


    },
    methods: {

        async putenquiry1() {
            var that = this
            console.log(that.enquiry)
            var form = that.enquiry
            console.log(form, '+++');
            // var worldAreaCode = that.worldAreaCode
            for (var i in form) {
                if (form[i] == 'undefined' || !form[i] || !/[^\s]/.test(form[i])) {
                    that.$message({
                        message: '必填内容不可为空',
                        type: 'error'
                    });
                    // uni.$u.toast('必填内容不可为空')
                    return
                }
            }
            await contactUs({
                name: that.enquiry.Name, //名
                email: that.enquiry.Email, //姓
                phone: that.enquiry.Phone, //手机号
                subject: that.enquiry.Subject, //邮箱
                message: that.enquiry.Message //咨询内容
            }).then(res => {
                console.log('res', res)
                if (res.code == 1) {

                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    that.enquiry = {
                        Name: '',
                        Email: '',
                        Phone: '',
                        Subject: '',
                        Message: ''
                    }
                    // 获取到前面打过标签的元素，将其scrollTop属性设置为0
                    document.documentElement.scrollTop = 0
                    // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
                    document.body.scrollTop = 0


                }

                console.log('res', res)
            })

        },


    },

}

</script>
<style scoped>
/* 针对移动端的样式 */
.severe {
    margin-bottom: 20px;
}


/* .sb11,
  .sb12,
  .sb13 {
  
   
    margin: 20px auto;
  
  
  } */

.sb11,
.sb13 {
    background: #101112;
}

.sb12 {
    background: #050809;

}

.flex1 {
    display: flex;
    /* flex-wrap: wrap; */
}


.sb1,
.sb3 {
    padding: 120px;
    width: 75%;
    margin: 0px auto;
    /* margin:50px 30px; */
    align-items: center;
    justify-content: space-between;
    background: #101112;
}

.sb2 {
    width:75%;
    padding: 120px;
    background: #050809;
    /* margin:50px 30px; */
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;
}

.w1000 {
    width: 80%;
    height: 100%;
    border-radius: 20px;
}




.w24 {
    width: 24px;
    height: 24px;
}

.botton1 {
    padding: 10px 16px;

    /* height: 23px; */
    background: #1495FF;
    text-align: center;
    margin-right: 20px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 22px;
    color: #111111;
    /* line-height: 48px; */

    font-style: normal;
    text-transform: none;
}

.severbox {
    margin-top: 120px;
}



.sb_l {
    width: 45%;
    /* height:95%; */

    background: #1D2023;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid rgba(255, 255, 255, 0.4000000059604645);
    /* border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.4000000059604645), rgba(0, 0, 0, 0)) 1 1; */
    text-align: center;
    /* margin: 80px 100px; */
}

.sb_l_img {
    width: 100%;
    min-height: 350px;
    /* height: 100%; */
    border-radius: 20px;
}

.sb_l1 {
    width: 697px;
    height: 620px;
    text-align: center;
    /* margin: 80px 100px; */
}

.sb_r {
    width: 45%;
    /* margin: 80px 150px; */

}


.sb_r_t {


    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 53px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.sb_r_m {
    margin: 20px 0;

    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.sb_r_b {
    /* padding: 30px; */
    background: #1495FF;
    max-width: 250px;
    cursor: pointer;
    border-radius: 5px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}

.sb_r_call,
.sb_r_email {
    align-items: center;
    margin: 30px auto;
}

.call_img,
.email_img {
    width: 40px;
    height: 40px;
    background: rgba(38, 208, 255, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-right: 10px;
    line-height: 50px;
    text-align: center;
    /* opacity: 0.1; */
}

.call_img1,
.email_img1 {
    width: 20px;
    height: 20px;
}

.d_input {
    display: grid;
    /* width: 80%; */
    /* width: 70%; */
    margin: 20px auto;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
}

.d_input_input {
    text-align: left;
}

.d_input_input_title {
    margin-bottom: 10px;

    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 20px;
    color: #DDDDDD;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.el-input__inner {
    background: rgba(255, 255, 255, 0.12);
}

.input_input3 {
    /* width: 286px;
    height: 56px; */
    background: rgba(255, 255, 255, 0.12);
    border-radius: 8px 8px 8px 8px;
    /* opacity: 0.12; */
}

.input_input2 {
    /* width: 286px;
    height: 56px; */
    background: rgba(255, 255, 255, 0.12);
    border-radius: 8px 8px 8px 8px;
    /* opacity: 0.12; */
}

.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}
</style>