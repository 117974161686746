<!--  -->
<template>
    <div>
        <div class="newbox">
            <div class="new_title1">
                Our Showcase
            </div>
            <div class="new_title2">
                Unbound AI's mission is to provide customized intelligent solutions for various industries and research
            </div>
            <div class="listboxs ">

                <div class="listbox" v-if="contactlist.length > 0">
                    <div class="listbox1" :style="{ background: `url(${item.image})`, 'background-size': '100% 100%' }"
                        v-for="(item, index) in contactlist" :key="index" @click="SeverePage(index,item)">
                        <div class="bottom flex1">

                        </div>
                        <div class="top">
                            {{ item.title }}

                        </div>
                        <div class="mid flex1">
                            {{ item.intro }}
                        </div>



                    </div>
                    <!-- <div class="listbox1" :style="{ backgroundImage: 'url(' + require('../static/index/cs1.png') + ')' }"
                       >
                        <div class="bottom flex1">
          
                        </div>
                        <div class="top">
                            {{ contactlist[0].title }}

                        </div>
                        <div class="mid flex1">
                            {{ contactlist[0].context }}
                        </div>
                      


                    </div> 
                    <div class="listbox1" :style="{ backgroundImage: 'url(' + require('../static/index/cs2.png') + ')' }"
                       @click="houseDetail(contactlist[1])">
                        <div class="bottom flex1">
          
                        </div>
                        <div class="top">
                            {{ contactlist[1].title }}

                        </div>
                        <div class="mid flex1">
                            {{ contactlist[1].context }}
                        </div>
                      


                    </div> 
                    <div class="listbox1" :style="{ backgroundImage: 'url(' + require('../static/index/cs3.png') + ')' }"
                       >
                        <div class="bottom flex1">
          
                        </div>
                        <div class="top">
                            {{ contactlist[2].title }}

                        </div>
                        <div class="mid flex1">
                            {{ contactlist[2].context }}
                        </div>
                      


                    </div> 
                     -->

                </div>
                <div v-else>
                    <el-empty description="Empty"></el-empty>
                </div>

            </div>
            <div class="togo flex1" @click="togo">
                LEARN MORE
                <i class="el-icon-right ml10"></i>
            </div>
            <div v-if="dialogTableVisible" class="dialog">
                <el-dialog title="New" :visible.sync="dialogTableVisible">
                    <div class="dialog">
                        <div class="">
                            <div class="title">
                                {{ housedetail.title }}
                            </div>
                            <div class="time">{{ housedetail.createtime | formatDate }}</div>
                            <img :src="housedetail.image_text" class="image" alt="">
                            <div class="intro">
                                {{ housedetail.intro }}
                            </div>

                            <div v-html="housedetail.content"></div>
                        </div>
                    </div>


                </el-dialog>
            </div>
        </div>


    </div>
</template>

<script>
import { ourShow } from '../request/api'
export default {
    name: 'NewList',
    props: {
        msg: String,
        hourelist: Array
    },



    data() {
        return {
            dialogTableVisible: false,
            housedetail: {},
            page: 1,
            value: '',
            houseid: 0,
            screenWidth: document.body.clientWidth,//初始化宽度
            contactlist: [{
                image: 'https://nelsons.buzzegg.cn/uploads/20240608/3f5961dd7f75c05aa0fd3334c97b7b33.png',
                title: 'Ceramic Building Materials Industrial IoT Platform',
                context: 'Unbound AI applied advanced big data analytics and AI algorithms to a ceramic building materials industrial IoT platform.'
            }, {
                image: 'https://nelsons.buzzegg.cn/uploads/20240608/3f5961dd7f75c05aa0fd3334c97b7b33.png',
                title: 'AI-Driven Acoustic Metamaterials Design',
                context: 'Unbound AI achieved significant breakthroughs in the design of acoustic metamaterials using AI technology.'
            }, {
                image: 'https://nelsons.buzzegg.cn/uploads/20240608/3f5961dd7f75c05aa0fd3334c97b7b33.png',
                title: 'Scientific Research',
                context: 'AI algorithms analyze vast amounts of medical data to provide more precise diagnostics, reducing the chances of misdiagnosis and enabling early intervention'
            }],

            picture: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240514/d7aabb04cdfa5994a3816dad54606284.jpg',
        }
    },
    async created() {
        // this.initMap()
        this.getdata()

        // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
    },

    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },

    methods: {
        togo() {
            this.$emit('TabEvent', this.$t('Tab')[1])
            this.$store.commit('updatetypetab', this.$t('Tab')[1])
        },
        SeverePage(index,item) {
            console.log(index,item)
            // this.housedetail = item
            // this.dialogTableVisible = true
            // this.houseid = item.id
            // this.$emit('scrollindex',)
            localStorage.setItem('scrollindex', Number(index)+1);
            localStorage.setItem('scrollid', item.id);
            // // 获取到前面打过标签的元素，将其scrollTop属性设置为0
           
            // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
           
            this.$emit('TabEvent', this.$t('Tab')[1])
            this.$store.commit('updatetypetab', this.$t('Tab')[1])
           

        },
        async getdata() {
            await ourShow({


                page: this.page, //页码

                limit: 12 //每页显示条数
            }).then(res => {

                var pagelist = []
                pagelist = res.data.list
                if (this.page == 1) {
                    this.contactlist = pagelist
                }
                else {
                    this.contactlist = this.list.concat(pagelist)
                }


            })
        },
        onClick(name, title) {

            this.tabtype = title
            this.$emit('HouseId', this.houseid)
        },
        

    }
}

</script>

<style scoped>
.newbox {
    padding-top: 20px;
    padding-bottom: 20px;
    /* padding: 90px 380px; */
    text-align: center;
    background: #050809;
}
.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}

.new_title1 {
    /* width: 257px; */
    margin-top: 80px;
    margin-bottom: 23px;
    height: 53px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 53px;

}

.new_title2 {
    /* width: 257px; */
    /* margin-top: 80px; */
    margin-bottom: 23px;
    /* width: 320px; */
    height: 16px;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #9E9E9E;
    line-height: 16px;


}

.tabbg {
    width: 100%;
    height: 40vh;
}

/* /deep/ .el-dialog {
    width: 80%;
} */





.listboxs {
    /* margin: 10px 100px; */
}


.listbox {
    display: grid;
    /* width: 80%; */
    width: 70%;
    margin: 20px auto;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;

}

.listbox1 {
    margin: 10px;
    /* display: flex; */
    /* 修改主轴方向成列 */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    border-radius: 10px;
    /* border: 1px solid #E5E5E5; */
    text-align: left;

    /* width: 75%; */
    cursor: pointer;
    background-size: cover;
    /* 可选，根据需要调整 */
    background-repeat: no-repeat;
    /* 可选，根据需要调整 */
    background-position: center;
    /* 可选，根据需要调整 */

}
.listbox1:hover {
    width:90%;
    height: 100%;
    margin: 10px;
    /* display: flex; */
    /* 修改主轴方向成列 */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    border-radius: 10px;
    /* border: 1px solid #E5E5E5; */
    text-align: left;

    /* width: 75%; */
    cursor: pointer;
    background-size: cover;
    /* 可选，根据需要调整 */
    background-repeat: no-repeat;
    /* 可选，根据需要调整 */
    background-position: center;
    /* 可选，根据需要调整 */

}


.mid {
    width: 100%;
    margin: 16px 0;
    /* align-self: flex-end; */
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 200px; */
    /*将对象作为弹性伸缩盒子模型显示*/
    display: -webkit-box;
    /*限制文本行数*/
    -webkit-line-clamp: 4;
    /*子元素的排列方式*/
    -webkit-box-orient: vertical;
    /*将对象作为弹性伸缩盒子模型显示*/

}


.top {
    /* align-self: flex-end; */
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 34px;

}




.bottom {
    /* text-align: center; */

    align-items: center;
    margin-bottom: 10px;
    margin-top: 150px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #1495FF;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.bottom_btn1 {
    margin: 10px auto;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    text-align: center;

    color: #FFFFFF;
    line-height: 22px;
    letter-spacing: 1px;
    padding: 10px 0;
    /* height: 52px; */
    background: #1495FF;
}

.bottom_btn2 {
    margin: 10px auto;
    width: 100%;
    font-weight: 700;
    color: #1495FF;
    border: 1px solid #1495FF;
    font-size: 16px;
    text-align: center;


    line-height: 22px;
    letter-spacing: 1px;
    padding: 10px 0;
    /* height: 52px; */
    background: #FFFFFF;
}

.name {
    max-height: 60px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 20px;
    color: #888888;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.texttitle {
    margin: 20px auto;
    /* width: 217px;
    height: 24px; */
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #111111;
    /* line-height: 24px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.line {
    margin: 10px auto;
    width: 25px;
    height: 3px;
    background: #1495FF;
}

.title_input {
    margin: 20px auto;
    width: 200px;
}





.about_us_m {
    margin: 80px 280px;
}

.title {
    text-align: left;
    margin: 20px auto;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    line-height: 22px;

}



.d_u_m_l {
    /* width: 680px;
    height: 616px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.d_u_m_r {
    /* width: 370px; */
    /* height: 692px; */
    background: #F7FBFF;
    border: 1px solid #DDDDDD;
}

.about_title {

    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    /* line-height: 22px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.about_boxs {
    margin-top: 30px;
}

.about_box {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    color: #1495FF;
    /* line-height: 78px; */
    text-align: center;

}

.time {
    width: 100%;

    text-align: right;
    margin: 20px auto;
    margin-right: 30px;
}

.image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.dialog {
    height: 60vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
}

.dialog1 {
    width: 90%;
    height: 60vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
}
</style>