// router.js
import Vue from 'vue';
import Router from 'vue-router';
// import TabOne from '@/Tabs/TabOne.vue';
// import TabTwo from '@/Tabs/TabTwo.vue';
import TabOneVue from '@/Tabs/TabOne.vue'


import TabTwoVue from '@/Tabs/TabTwo.vue'

import TabThreeVue from '@/Tabs/TabThree.vue'

import TabFourVue from '@/Tabs/TabFour.vue'

Vue.use(Router);

export default new Router({
  
  routes: [
    {
      path: '/',
      name: 'TabOne',
      component: TabOneVue,
      
    },
    // {
    //   path: 'HouseDetail/:houseid',
    //   name: 'HouseDetail',
    //   component: HouseDetail
     

    // },
    {
      path: '/TabTwoVue',
      name: 'TabTwo',
      component: TabTwoVue,
    
    },
    {
      path: '/TabThreeVue',
      name: 'TabThree',
      component: TabThreeVue,
     
    },
    // {
    //   path: '/HouseDetail/:houseid',
    //   name: 'HouseDetail',
    //   component: HouseDetail
    // },

    {
      path: '/TabFourVue',
      name: 'TabFour',
      component: TabFourVue
    },
  
  ]
  
});
// // 全局前置守卫

// Router.beforeEach((to, from, next) => {  
//   window.scrollTo(0, 0);  
//   next();  
// });