<!--  -->
<template>
  <div>

    <div class="severe">
      <div class="severboxs">
        <div class="sb11" v-if="type == 1" :id=" newdata.id">
          <div class="flex-between sb1  flex1">
            <div class="sb_l">

              <img :src="newdata.image" mode="widthFix" alt="" class="sb_l_img">
              <!-- <img src="../static/index/cs1.png" mode="widthFix" alt="" class="sb_l_img"> -->
            </div>
            <div class="sb_r">
              <div class="sb_r_t">
                <!-- Ceramic Building Materials Industrial IoT Platform -->
                {{ newdata.title }}
              </div>
              <div class="sb_r_m">
                
                {{ newdata.intro }}
              </div>
             
            </div>
          </div>

        </div>
        <div class="sb12" v-else-if="type == 2" :id=" newdata.id">
          <div class="sb2 flex1">
            <div class="sb_r">
             
              <div class="sb_r_t">
                <!-- AI-Driven Acoustic Metamaterials Design -->
                {{ newdata.title }}
              </div>
              <div class="sb_r_m">
                <!-- Unbound AI achieved significant breakthroughs in the design of acoustic metamaterials using AI
                technology. By creating a genomic database for acoustic metamaterials and developing specific
                algorithms, the project enabled data-driven rapid forward prediction and on-demand inverse design of
                materials. This not only accelerated the design process but also allowed for custom designs to be
                completed within 30 minutes, driving innovation in the field of acoustic metamaterials. These cases
                demonstrate Unbound AI's expertise and practical application of leading technologies in the fields of
                smart manufacturing and materials science. -->
                {{ newdata.intro }}
              </div>
              <!-- <div class="sb_r_b" @click="houseDetail(newdata)">
              {{ buttontile1 }}
            </div> -->
            </div>
            <div class="sb_l">
              <img :src="newdata.image" mode="widthFix" alt="" class="sb_l_img">
              <!-- <img :src="newdata.image_text" mode="widthFix" alt="" class="sb_l_img"> -->
            </div>
          </div>
        </div>
        <div class="sb13" v-else-if="type == 3" :id="newdata.id">
          <div class="flex-between sb3  flex1">
           
           
            <div class="sb_l">
              <img src="../static/index/c5.png" mode="widthFix" alt="" class="sb_l_img">
              <!-- <img :src="newdata.image" mode="widthFix" alt="" class="sb_l_img"> -->

            </div>
            <div class="sb_r">
              <div class="sb_r_t">
                <!-- AI-Driven Solutions for Disease Management -->
                {{ newdata.title }}
              </div>
              <div class="sb_r_m">
                <!-- Enhanced Diagnostic Accuracy AI algorithms analyze vast amounts of medical data to provide more precise
                diagnostics, reducing the chances of misdiagnosis and enabling early intervention -->
                {{ newdata.intro }}
              </div>
              <!-- <div class="sb_r_b" @click="houseDetail(newdata)">
              {{ buttontile1 }}
            </div> -->
            </div>
          </div>
        </div>
        <div class="sb12" v-else-if="type ==4" :id="newdata.id">
          <div class="sb2 flex1">
           
            
            <div class="sb_r">
             
             <div class="sb_r_t">
               <!-- AI-Driven Acoustic Metamaterials Design -->
               {{ newdata.title }}
             </div>
             <div class="sb_r_m">
               <!-- Unbound AI achieved significant breakthroughs in the design of acoustic metamaterials using AI
               technology. By creating a genomic database for acoustic metamaterials and developing specific
               algorithms, the project enabled data-driven rapid forward prediction and on-demand inverse design of
               materials. This not only accelerated the design process but also allowed for custom designs to be
               completed within 30 minutes, driving innovation in the field of acoustic metamaterials. These cases
               demonstrate Unbound AI's expertise and practical application of leading technologies in the fields of
               smart manufacturing and materials science. -->
               {{ newdata.intro }}
             </div>
             <!-- <div class="sb_r_b" @click="houseDetail(newdata)">
             {{ buttontile1 }}
           </div> -->
           </div>
           <div class="sb_l">
              <img src="../static/index/c4.png" mode="widthFix" alt="" class="sb_l_img">
              <!-- <img :src="newdata.image" mode="widthFix" alt="" class="sb_l_img"> -->

            </div>
          </div>
        </div>
        <div class="sb13" v-else-if="type ==5" :id=" newdata.id">
          <div class="flex-between sb3  flex1">
           
          
            <div class="sb_l">
              <img src="../static/index/c6.png" mode="widthFix" alt="" class="sb_l_img">
              <!-- <img :src="newdata.image" mode="widthFix" alt="" class="sb_l_img"> -->

            </div>
            <div class="sb_r">
              <div class="sb_r_t">
                <!-- AI-Driven Solutions for Disease Management -->
                {{ newdata.title }}
              </div>
              <div class="sb_r_m">
                <!-- Enhanced Diagnostic Accuracy AI algorithms analyze vast amounts of medical data to provide more precise
                diagnostics, reducing the chances of misdiagnosis and enabling early intervention -->
                {{ newdata.intro }}
              </div>
              <!-- <div class="sb_r_b" @click="houseDetail(newdata)">
              {{ buttontile1 }}
            </div> -->
            </div>
          </div>
        </div>

       

      </div>



    </div>
  </div>
</template>

<script>
export default {

  props: {
    type: Number,
    newdata: Object,
    buttontitle: String
  },
  data() {
    return {
      formInline: {
        name: '',
        Search_location: '',
        Type: '',
        Min_bedrooms: '',
        Min_Price: '',
        Max_Price: '',
        Exclude_sold_properties: '',
        SEARCH_PROPERTIES: '',
        Distance: ''
      },
      screenWidth: document.body.clientWidth,//初始化宽度
      houseid: 0,

      tabtype: 0,
      tabtype1: 0,
      list: [],
      radio: 0,
      severlist: {},
      buttontile1: '',
      buttonpath: '',
    }
  },
  async created() {
    this.getdata()
    //     our_service：我们的服务
    // contact_us：联系我们
    // about_us：关于我们
    // our_team：我们的团队
    // join_team：加入团队
    // tenement：物业建议
    // buy_house：我要买房
    // rent_house：我要租房
    // flat：服务公寓/短租
    // fitment：装修改建
    // sell_house：卖房估价
    // loan：贷款建议
    // team_member：团队人员

    console.log(this.buttontitle, 'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    if (this.buttontitle == 'our_service') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'SERVICES WE OFFER' : '我们的服务'
      this.buttonpath = this.$t('Tab')[3]
      // this.$emit('TabEvent', this.tabtype)
      // this.$store.commit('updatetypetab',  this.tabtype)
    }
    else if (this.buttontitle == 'contact_us') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'READ OUR REVIEWS' : '联系我们'
      this.buttonpath = this.$t('Tab1')[3]

    }
    else if (this.buttontitle == 'about_us') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'FIND YOUR BRANCH' : '关于我们'
      this.buttonpath = this.$t('Tab1')[0]

    }
    else if (this.buttontitle == 'our_team') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'MEET OUR TEAM' : '团队人员'
      this.buttonpath = this.$t('Tab1')[1]

    }
    else if (this.buttontitle == 'join_team') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'JOIN OUR TEAM' : '加入我们'
      this.buttonpath = this.$t('Tab1')[1]

    }
    else if (this.buttontitle == 'tenement') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'tenement' : '物业建议'
      this.buttonpath = this.$t('Tab')[4]

    }
    else if (this.buttontitle == 'buy_house') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'Buy House' : '我要买房'
      this.buttonpath = this.$t('Tab')[1]

    }
    else if (this.buttontitle == 'rent_house') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'Rent House' : '我要租房'
      this.buttonpath = this.$t('Tab')[2]

    }
    else if (this.buttontitle == 'flat') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '服务公寓/短租'
      this.buttonpath = this.$t('Tab')[3]

    }
    else if (this.buttontitle == 'fitment') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '装修改建'
      this.buttonpath = this.$t('Tab')[4]

    }
    else if (this.buttontitle == 'sell_house') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'BOOK A VALUATION' : '预约评估您待出售的房产'
      this.buttonpath = this.$t('Tab')[5]

    }
    else if (this.buttontitle == 'loan') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '贷款建议'
      this.buttonpath = this.$t('Tab')[6]

    }
    else if (this.buttontitle == 'team_member') {
      this.buttontile1 = this.$i18n.locale == 'en' ? 'SPEAK TO AN EXPERT' : '团队人员'
      this.buttonpath = this.$t('Tab1')[1]

    }

  },

  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth)
          that.timer = false
        }, 400)
      }
    },
    '$i18n.locale': {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        if (this.buttontitle == 'our_service') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'SERVICES WE OFFER' : '我们的服务'
          this.buttonpath = this.$t('Tab')[3]
          // this.$emit('TabEvent', this.tabtype)
          // this.$store.commit('updatetypetab',  this.tabtype)
        }
        else if (this.buttontitle == 'contact_us') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'READ OUR REVIEWS' : '联系我们'
          this.buttonpath = this.$t('Tab1')[3]

        }
        else if (this.buttontitle == 'about_us') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'FIND YOUR BRANCH' : '关于我们'
          this.buttonpath = this.$t('Tab1')[0]

        }
        else if (this.buttontitle == 'our_team') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'MEET OUR TEAM' : '团队人员'
          this.buttonpath = this.$t('Tab1')[1]

        }
        else if (this.buttontitle == 'join_team') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'JOIN OUR TEAM' : '加入我们'
          this.buttonpath = this.$t('Tab1')[1]

        }
        else if (this.buttontitle == 'tenement') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'tenement' : '物业建议'
          this.buttonpath = this.$t('Tab')[4]

        }
        else if (this.buttontitle == 'buy_house') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'Buy House' : '我要买房'
          this.buttonpath = this.$t('Tab')[1]

        }
        else if (this.buttontitle == 'rent_house') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'Rent House' : '我要租房'
          this.buttonpath = this.$t('Tab')[2]

        }
        else if (this.buttontitle == 'flat') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '服务公寓/短租'
          this.buttonpath = this.$t('Tab')[3]

        }
        else if (this.buttontitle == 'fitment') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '装修改建'
          this.buttonpath = this.$t('Tab')[4]

        }
        else if (this.buttontitle == 'sell_house') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'BOOK A VALUATION' : '预约评估您待出售的房产'
          this.buttonpath = this.$t('Tab')[5]

        }
        else if (this.buttontitle == 'loan') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'rent_house' : '贷款建议'
          this.buttonpath = this.$t('Tab')[6]

        }
        else if (this.buttontitle == 'team_member') {
          this.buttontile1 = this.$i18n.locale == 'en' ? 'SPEAK TO AN EXPERT' : '团队人员'
          this.buttonpath = this.$t('Tab1')[1]

        }
      },
      deep: true // 开启深度监听
    }


  },
  methods: {
    // tabclick(item, index) {
    //   this.tabtype = index
    // },
    tabclick1(item, index) {
      this.tabtype1 = index
    },
    getHouseId(data) {
      console.log('父组件接收到的数据：' + data)
      this.houseid = data
      // if (this.houseid) {
      //   this.currentComponent = 'HouseDetailVue'
      // }
    },
    houseDetail(item) {
      console.log(item)
      console.log(this.buttonpath)
      this.$emit('TabEvent', this.buttonpath)
      this.$store.commit('updatetypetab', this.buttonpath)
      // 获取到前面打过标签的元素，将其scrollTop属性设置为0
      document.documentElement.scrollTop = 0
      // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
      document.body.scrollTop = 0

      // this.houseid = item.id
      // this.$emit('HouseId', this.houseid)
      // // 获取到前面打过标签的元素，将其scrollTop属性设置为0
      // document.documentElement.scrollTop = 0
      // // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
      // document.body.scrollTop = 0
      // window.open("https://www.baidu.com", "_blank");



    },
    async getdata() {


    }

  },

}

</script>
<style scoped>
/* 针对移动端的样式 */
.severe {
  margin-bottom: 20px;
}


/* .sb11,
.sb12,
.sb13 {

 
  margin: 20px auto;


} */

.sb11,
.sb13 {
  background: #101112;
}

.sb12 {
  background: #050809;

}

.flex1 {
  display: flex;
  /* flex-wrap: wrap; */
}


.sb1,
.sb3 {
  padding: 100px;
  width:75%;
  margin: 0px auto;
  /* margin:50px 30px; */
  align-items: center;
  justify-content: space-between;
  background: #101112;
}

.sb2 {
  width:75%;
  padding: 100px;
  background: #050809;
  /* margin:50px 30px; */
  margin: 0px auto;
  align-items: center;
  justify-content: space-between;
}

.w1000 {
  width: 80%;
  height: 100%;
  border-radius: 20px;
}




.w24 {
  width: 24px;
  height: 24px;
}

.botton1 {
  padding: 10px 16px;

  /* height: 23px; */
  background: #1495FF;
  text-align: center;
  margin-right: 20px;
  background: #F5F5F5;
  border-radius: 8px 8px 8px 8px;
  align-items: center;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 22px;
  color: #111111;
  /* line-height: 48px; */

  font-style: normal;
  text-transform: none;
}

.severbox {
  margin-top: 80px;
}



.sb_l {
  width: 45%;
  /* height:95%; */
  text-align: center;
  /* margin: 80px 100px; */
}

.sb_l_img {
  width: 100%;
  min-height: 350px;
  /* height: 100%; */
  border-radius: 20px;
}

.sb_l1 {
  width: 697px;
  height: 620px;
  text-align: center;
  /* margin: 80px 100px; */
}

.sb_r {
  width: 45%;
  /* margin: 80px 150px; */

}


.sb_r_t {


  font-family: Futura, Futura;
  font-weight: 500;
  font-size: 40px;
  color: #FFFFFF;
  line-height: 53px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.sb_r_m {
  margin: 20px 0;

  font-family: Helvetica, Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: #DDDDDD;
  line-height: 28px;
  text-align: left;


}

.sb_r_b {
  /* padding: 30px; */
  background: #1495FF;
  max-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
</style>