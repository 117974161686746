<template>
    <div>
        <!-- {{ $store.state.typetab  }} -->
        <!-- <div class="topss" :style="{ 'width': screenWidth + 'px' }"> -->
        <div class="topss">

            <div class="top01">
                <div class="tops">

                    <div class="weblog flex1" @click="shouyepage">

                        <img src="../static/jx.png" class="weblogo" alt="">
                        Unbound AI
                    </div>
                    <div class="tobtab">



                        <div class="flex1" v-if="$store.state.typetab">
                            <div v-for="(item, index) in $t('Tab')" :key="index"
                                :class="$store.state.typetab == item ? 'tabbox2  pr30' : 'tabbox0  pr30'"
                                @click="onClick(index, item)">
                                <div>
                                    {{
            item }}
                                </div>
                                <div :class="$store.state.typetab == item ? 'xian1' : 'xian2'"></div>


                            </div>
                            <!-- <router-link to="/TabTwo">TabTwo</router-link> -->

                        </div>


                    </div>

                    <!-- <el-button type="text" @click="open"> {{ $t('login_in') }}</el-button> -->
                </div>
            </div>




        </div>


    </div>

</template>

<script>
// import { login, register } from '../request/api'
import myObject from './countries.json'
export default {
    name: 'HomeVue',
    data() {
        return {
            myObject,
            dialogFormVisible: false,
            form: {
                name: '',
                password: ''
            },
            dialogFormVisible1: false,
            form1: {
                name: '',
                password: '',
                password1: '',
                tel: '',
                quyu: ''
            },
            formLabelWidth: '20%',
            menushow: false,
            screenWidth: document.body.clientWidth,//初始化宽度
            refresh: this.$t('refresh'), // 第三种用法，不过这种用法在切换预言时，需要刷新页面才会生效
            dialogVisible: false,
            languageVal: this.$i18n.locale,
            options: [{
                value: 'cn',
                label: '中文'
            }, {
                value: 'en',
                label: 'English'
            }],
            tabtype: this.$t('Tab')[0],
            activeName: this.$t('Tab')[0],
            active: 0,
            timer: false
        }
    },
    methods: {
        showdialogFormVisible() {
            this.dialogFormVisible = true
            this.dialogFormVisible1 = false

        },
        showdialogFormVisible1() {
            this.dialogFormVisible1 = true
            this.dialogFormVisible = false

        },
        menushowclick() {
            this.menushow = true
        },
        handleCommand(command) {
            console.log(command)
            this.tabtype = command
            this.$emit('TabEvent', this.tabtype)
        },
        quyuChange(e) {
            console.log(e, '区域')
            this.form1.quyu = e
            console.log(' this.form1', this.form1)
        },
        langChange(e) {
            console.log(e, '语言')

            this.$i18n.locale = e;
            localStorage.setItem('lang', e);
            // window.location.reload() // 第三种用法刷新页面
            // this.$router.go(0)
            this.$store.commit('updatelang', e)
            // console.log('this.tabtype', this.tabtype)
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        onClick(name, title) {

            this.tabtype = title

            // this.$emit('TabEvent', this.tabtype)
            this.$store.commit('updatetypetab', title)

        },
        // onClick1(index, title) {
        //     console.log('index', index)
        //     this.tabtype = title
        //     console.log('titletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitle', title)
        //     // this.$emit('TabEvent', this.tabtype)

        //     this.$store.commit('updatetypetab', title)


        // },
        // async login() {
        //     this.dialogFormVisible = false

        //     await login({
        //         account: this.form.name, //账号
        //         password: this.form.password//密码
        //     }).then(res => {
        //         // console.log('res', res)
        //         if (res.data) {
        //             this.$message({
        //                 message: 'Congratulations, login successful',
        //                 type: 'success'
        //             });
        //             this.$store.commit('updateuserinfo', res.data.userinfo)
        //             if (this.$store.state.userinfo) {
        //                 localStorage.setItem('token', this.$store.state.userinfo.token);
        //             }
        //             // this.$message({
        //             // message: '恭喜你，注册成功',
        //             // type: 'success'

        //         }

        //     })

        // },
        // async register() {
        //     this.dialogFormVisible1 = false
        //     if (this.form1.password == this.form1.password1) {
        //         await register({
        //             email: this.form1.name, //账号
        //             password: this.form1.password, //密码
        //             mobile: this.form1.tel, //手机号
        //             area_code: this.form1.quyu, //区号

        //         }).then(res => {
        //             console.log('res', res)
        //             if (res) {
        //                 this.$message({
        //                     message: 'Congratulations, registration successful',
        //                     type: 'success'
        //                 });
        //                 this.dialogFormVisible = true

        //             }


        //         })
        //     }
        //     else {
        //         this.$message({
        //             message: 'Inconsistent passwords before and after',
        //             type: 'error'
        //         });
        //     }

        // },
        shouyepage() {
            this.$router.push({ path: '/' });
            this.$store.commit('updatetypetab', this.$t('Tab')[0])
        },

        open() {
            this.$alert('<strong>这是 <i>HTML</i> 片段</strong>', 'HTML 片段', {
                dangerouslyUseHTMLString: true
            });


        }

    },
    watch: {
        '$route': {
            handler: function (to, from) {
                // 路由切换时会触发这个方法
                // to 和 from 分别代表切换后和切换前的路由对象
                console.log('Route changed from', from.name, 'to', to.name);
                var oldVal = to.name
                console.log(typeof oldVal, oldVal)
           
                if (oldVal == 'TabTwoVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[1])

                }
                if (oldVal == 'TabThreeVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[2])

                }
                if (oldVal == 'TabFour') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[3])

                }
              
                // else{
                //     console.log("haaaaaaaaaaaaaaaaaaaaaa")
                //     // this.currentPath = this.$t('Tab')[1]
                //     this.$store.commit('updatetypetab', this.$t('Tab')[6])
                // }
                

                console.log(this.tabtype, ' this.tabtype  this.tabtype  this.tabtype  this.tabtype ')
                // 获取到前面打过标签的元素，将其scrollTop属性设置为0
                document.documentElement.scrollTop = 0
                // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
                document.body.scrollTop = 0

                // 在这里可以根据路由变化执行相应的逻辑
            },
            // 如果需要深度监听路由对象中的所有属性，可以设置deep: true
            deep: true,
            immediate: true,
        },
      
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },
        Path() {
            return this.$route.path // 获取当前路由路径
        },

        getuserinfo() {
            return this.$store.state.userinfo;
        },

    },

    created() {
   
        console.log(this.tabtype, ' this.tabtype this.tabtype this.tabtype')
        console.log(this.$store.state.typetab, ' this.tabtype this.tabtype this.tabtype')
        console.log(this.$store.state.userinfo, 'userinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfo')
        if (this.$store.state.userinfo) {
            localStorage.setItem('token', this.$store.state.userinfo.token);
        }
        // this.$emit('TabEvent', this.tabtype)

    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },


}
</script>
<style scoped>
.login {
    cursor: pointer;
    margin-left: 40px;
}

.login_input {
    width: 70%;
}

.login_input1 {
    width: 30%;
}

.w48 {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
}


/* 针对桌面端的样式 */

.tabbox0 {
    cursor: pointer;
    /* font-family: Montserrat, Montserrat; */
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 20px;
}

.login_input_select {
    width: 150px;
}

.tabbox1 {
    cursor: pointer;



    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 20px;

}

.tabbox2 {
    cursor: pointer;

    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 20px;
    color: #26D0FF;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.activelink {

    /* font-family: Montserrat, Montserrat; */
    font-weight: 600;
    font-size: 36px;
    color: #1495FF;

    text-align: left;
    font-style: normal;
    text-transform: none;
}

.top01 {
    /* width: 100%; */
    margin: 10px 10vw;
    margin-top: 20px;
}

.tops {
    display: flex;
    width: 100%;
    /* width: 1920px;
        height: 80px; */
    background: #101112;
    color: #fff;
    align-items: center;
    margin-top: 0;
    justify-content: space-between;
}

.weblog {
    padding-bottom: 10px;
    cursor: pointer;
    color: #fff;
    align-items: center;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 43px;
}

.van-tab {
    width: 200px !important;

}

.tobtab {
    margin-left: 20px;
    flex-wrap: wrap;
    /* width: 1000px; */
}

.tobtab1 {}

::v-deep .el-input__inner {
    height: 30px;
}


.weblogo {
    width: 44px;
    height: 44px;
    border-radius: 0px 0px 0px 0px;
}

.top0 {
    width: 95%;
    /* padding-right: 280px; */
    /* font-family: Montserrat, Montserrat; */
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    line-height: 20px;
    text-align: left;

}

.xian1 {
    margin: 10px auto;
    width: 50px;
    height: 3px;
    background: #26D0FF;
}
.xian2 {
    margin: 10px auto;
    width: 50px;
    height: 3px;
    background: #101112;
}
</style>
