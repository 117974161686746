<!--  -->
<template>
    <div>

        <div class="about_us_m   ">

            <CaseServicesVue></CaseServicesVue>
        </div>
        <div class="about_us_b" v-for="(item, index) in contactlist" :key="index">
            <!-- {{ Number(index%2)+1 }} -->
            <SeverePageVue :newdata="item" :buttontitle="'sell_house'" :type="Number(index % 2) + 1"></SeverePageVue>
            <!-- <SeverePageVue :newdata="newdata1" :buttontitle="'sell_house'" :type="1"></SeverePageVue>
            <SeverePageVue :newdata="newdata2" :buttontitle="'contact_us'" :type="2"></SeverePageVue>
            <SeverePageVue :newdata="newdata3" :buttontitle="'contact_us'" :type="3"></SeverePageVue> -->

            <!-- <EqueryFormVue></EqueryFormVue> -->
        </div>
        <el-dialog title="New" :visible.sync="dialogTableVisible">
            <div class="dialog">
                <div class="">
                    <div class="title">
                        {{ housedetail.title }}
                    </div>
                    <div class="time">{{ housedetail.createtime | formatDate }}</div>
                    <img :src="housedetail.image_text" class="image" alt="">
                    <div class="intro">
                        {{ housedetail.intro }}
                    </div>

                    <div v-html="housedetail.content"></div>
                </div>
            </div>


        </el-dialog>
    </div>
</template>

<script>

import SeverePageVue from '@/components/SeverePage.vue';

import { ourShow } from '../request/api'
import CaseServicesVue from '@/components/CaseServices.vue';
export default {
    name: 'TabTwo',

    components: {

        SeverePageVue,
        CaseServicesVue
    },


    data() {
        return {
            dialogTableVisible: false,
            housedetail: {},
            contactlist: [],
            banner1: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            banner2: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            our_service: {},
            contact_us: {},
            about_us: {},
            our_team: {},
            join_team: {},
            tenement: {},

            buy_house: {},
            rent_house: {},
            flat: {},
            fitment: {},
            sell_house: {},
            loan: {},
            team_member: {},
            picture: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240514/d7aabb04cdfa5994a3816dad54606284.jpg',
            options: [
            ],
            value: '',
            page: 1,
            screenWidth: document.body.clientWidth,//初始化宽度
            newdata1: {
                title: 'Ceramic Building Materials Industrial IoT Platform',
                intro: 'Unbound AI applied advanced big data analytics and AI algorithms to a ceramic building materials industrial IoT platform. The project involved data collection, cleaning, classification, and modeling of production line data, which enhanced the intelligence of quality control and problem traceability. The platform significantly improved production efficiency and product quality while reducing costs. Additionally, the smart manufacturing system allowed for predictive analysis and automatic adjustment of key production parameters, ensuring more stable and efficient operations.'
            },
            newdata2: {
                title: 'AI-Driven Acoustic Metamaterials Design',
                intro: 'Unbound AI achieved significant breakthroughs in the design of acoustic metamaterials using AI technology. By creating a genomic database for acoustic metamaterials and developing specific algorithms, the project enabled data-driven rapid forward prediction and on-demand inverse design of materials. This not only accelerated the design process but also allowed for custom designs to be completed within 30 minutes, driving innovation in the field of acoustic metamaterials. These cases demonstrate Unbound AIs expertise and practical application of leading technologies in the fields of smart manufacturing and materials science.'
            }, newdata3: {
                title: 'AI-Driven Solutions for Disease Management',
                intro: 'Enhanced Diagnostic Accuracy AI algorithms analyze vast amounts of medical data to provide more precise diagnostics, reducing the chances of misdiagnosis and enabling early intervention'
            },
            inputValue: 0,
            inputid: 0
        }
    },
    async created() {
        this.getdata()
        this.inputValue = localStorage.getItem('scrollindex') || 0;
        this.inputid = localStorage.getItem('scrollid') || 0;

        console.log(this.inputid, 'inputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValuehaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        setTimeout(() => {
            // console.log(this.inputValue, 'inputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValueinputValuehaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
            // var scroll = this.inputValue * 850
            // window.scrollTo({
            //     top: scroll,
            //     behavior: 'smooth'
            // });
            // localStorage.setItem('scrollindex', 0);
            if (this.inputid) {
                const anchorId = this.inputid;
                const targetElement = document.getElementById(anchorId);
                if (targetElement) {
                    console.log('hhhhhhhhhh')
                    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                }
                localStorage.setItem('scrollid', 0);
            }
        }, 500);

    },
    // mounted() {
    //     console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhh', this.inputValue )
    //     window.addEventListener("scroll", 750);
    // },
    methods: {
        async getdata() {
            await ourShow({


                page: this.page, //页码

                limit: 12 //每页显示条数
            }).then(res => {

                var pagelist = []
                pagelist = res.data.list
                if (this.page == 1) {
                    this.contactlist = pagelist
                }
                else {
                    this.contactlist = this.list.concat(pagelist)
                }


            })
        },
    }
}

</script>
<style scoped>
.tabbg {
    width: 100%;
    height: 360px;
}

@media (max-width: 800px) {
    body {
        /* background-color: lightblue; */
    }

    .tablist {}

    .listboxs {
        text-align: center;
    }



    .listbox {
        width: 80%;
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
        cursor: pointer;
    }

    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .mid_box {
        width: 55%;
    }


    .topimg {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 8px 8px 8px 8px;
    }

    .top {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }


    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        /* text-align: center; */
        align-items: center;
        margin-bottom: 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1495FF;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 150px;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;

        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;


        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 352px;
        background-color: #333333;
    }

}

/* 针对桌面端的样式 */
@media (min-width: 801px) {


    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 452px;
        background-color: #333333;
    }

    .listbox {
        display: grid;
        /* width: 80%; */
        margin: 20px auto;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 20px;

    }

    .listbox1 {
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
        width: 75%;
        cursor: pointer;
        background-size: cover;
        /* 可选，根据需要调整 */
        background-repeat: no-repeat;
        /* 可选，根据需要调整 */
        background-position: center;
        /* 可选，根据需要调整 */

    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .mid_box {
        width: 55%;
    }

    .min_text1 {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .top {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }


    .topimg {
        width: 100%;
        height: 236px;
        border-radius: 3px 3px 3px 3px;
    }

    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        /* text-align: center; */

        align-items: center;
        margin-bottom: 10px;
        margin-top: 200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1495FF;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;

        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;


        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

}



.time {
    width: 100%;

    text-align: right;
    margin: 20px auto;
    margin-right: 30px;
}

.image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.dialog {
    height: 60vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
}

/* 针对桌面端的样式 */

.about_us_m {
    /* margin: 20px 10vw; */
}


.title {
    text-align: center;
    margin: 20px auto;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    line-height: 22px;

}


.d_u_m_l {
    /* width: 680px;
    height: 616px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.d_u_m_r {
    /* width: 370px; */
    /* height: 692px; */
    background: #F7FBFF;
    border: 1px solid #DDDDDD;
}

.about_title {

    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    /* line-height: 22px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.about_boxs {
    margin-top: 30px;
}

.about_box {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    color: #1495FF;
    /* line-height: 78px; */
    text-align: center;

}
</style>
