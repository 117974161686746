import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
    article: {
        text: '早上好，今天天气真好，祝你有个美好的一天。好好学习，天天向上。'
    },
    placeholder: '请选择',
    HelloWorld: {
        open: {
            button: '点击打开对话框'
        },
        title: '这是中文标题',
        cancel: '返回',
        confirm: '确认',
        close: {
            title: '确认关闭？',
        },
        tips: '提示'
    },
    Tab: ['首页',
        '我要买房',
        '我要租房',
        '服务公寓/短租',
        '装修改建',
        '卖房估价',
        '贷款建议',


    ],
    Tab1: [
        '关于我们',
        '团队人员',
        '新闻',
        '联系我们'

    ],
    bottom: {
        tab1: '实用链接',
        tab2: '其他信息',
        tabs1: [
            '首页',
            "关于我们",
            "团队人员",
            "新闻",
            "联系我们"
        ],
        name: '公司名',
        tel: '电话',
        email: '邮箱',

        code: '英国公司注册代码'
    },
    per_month: '每月',
    Form: {
        title: [
            '所有房源',
            '二手房',
            '新房',
            '商业地产',
            '海外新房'
        ],
        title1: [
            '我要买房',
            '我要租房',

        ],
        Search_location: '搜索房源',
        Type: '房屋类型',
        Min_bedrooms: '最小户型',
        Min_Price: '最低价格',
        Max_Price: '最高价格',
        Exclude_sold_properties: '包括已售价格',
        SEARCH_PROPERTIES: '搜索房源',
        Distance: '距离',
        available_date: '入驻日期',
        min_week_rent: "周租金最低金额", //周租金最低金额
        max_week_rent: "周租金最高金额", //周租金最高金额
        min_month_rent: "月租金最低金额", //月租金最低金额
        max_month_rent: "月租金最高金额", //月租金最高金额
        min_available_date: "最小入驻日期", //最小入驻日期
        max_available_date: "最大入驻日期", //最大入驻日期
    },
    Title: {
        title1: '我们致力于帮您寻找最适合的房产',
        title2: '我们经营20年，提供涵盖伦敦及其周边各区的丰富房产资源数据库。对于每一处房产，我们均提供详尽的资料，包括户型图、实拍照片及独特信息。',
        title3: '广泛且详细的房产数据库',
        title4: '详细的房产信息',
        title5: '专业的联系网络',
        title6: '预约评估您待出售的房产',

    },
    Any_price: '价格',
    week_price: '周租金',
    month_price: '月租金',
    PROPERTIES_FOR_SALE: '套房源',
    Save_Search: '收藏搜索',
    Map_Search: '地图搜索',
    Price_high_low: '价格从高到低',
    Price_low_high: '价格从低到高',
    LATEST_PROPERTIES: '附近房源',
    Share: '分享',
    Save: '收藏',
    ABOUT_THIS_PROPERTY: "房源信息",
    Map: '地图',
    DOWNLOAD:'下载',
    DOWNLOAD_ALL:'下载所有',
    TRAIN_STATIONS: '附近地铁/火车站',
    PROPERTY_TYPE: ' 财产类型',
    BEDROOMS: '卧室',
    BATHROOMS: '浴室',
    SIZE: '面积',
    TENURE: '任期',
    Mortgage: {
        Mortgage_calculator: '贷款计算器',
        Price: '房屋总价',
        Deposit: '首付',
        Loan_term: '贷款年限',
        Interest_rate: '利息',
        GET_MORTGAGE_ADVICE: '获取贷款咨询',
        per_month: '每月还款'
    },
    Yield: {
        Yield_calculator: '年化收益计算',
        Property_value: '财产价值',
        Annual_costs: '年度费用',
        Monthly_rent: '每月租金',
        Gross: '毛额',
        Net: '净额',
        GET_MORTGAGE_ADVICE: '获取按揭建议'
    },
    Stamp: {
        Stamp_duty_calculator: '印花税计算器',
        Property_value: '属性值',
        q1: '你是第一次买吗?',
        q2: '你是本土人士吗?',
        YOU_WILL_PAY: '您将支付：',
        GET_MORTGAGE_ADVICE: '获取按揭建议'
    },
    enquiry: {
        Make_enquiry: '向我们的住宅地产部门咨询',
        First_name: '姓',
        Last_name: '名',
        Phone: '手机',
        Email: '邮箱',
        Message: '消息',
        agree1: '订阅邮件，获取最新的本地房产市场动态、我们的产品和服务信息。您可以随时取消订阅。.',
        agree2: '我已阅读并同意',
        Terms_Conditions: "《条款与条件》",
        Privacy_Policy: '《隐私政策》',
        Cookies_Policy: '《Cookie政策》',
        Make_An_Enquiry: '发送您的需求',
        Call: '致电',
 
    },
    about_us: {
        Our_Departments: 'Our Departments:',
        Our_Department: [
            'ESTATE AGENTS & PROPERTY CONSULTANTS',
            'LETTINGS & MANAGEMENT',
            'BLOCK & ESTATE MANAGEMENT',
            'RURAL PROPERTY SERVICES',
            'COMMERCIAL SERVICES',
            'PROFESSIONAL VALUATIONS',
            'PLANNING CONSULTANTS',
            'LAND & NEW HOMES',
        ]
    },
    Contact_us: {
        OUR_BRANCHES: '我们机构',
        Enter: '在搜索框中输入您的邮政编码，以查找离您最近的分行。',
        COMMERCIAL_PROPERTY: '地产类型（商业地产，住宅地产）',
        SALES: '销售',
        LETTINGS: '租赁',
        DEPARTMENT: '公司内部部门',
        VIEW_BRANCH: '查看办公地址',
        EMAIL_COMMERCIAL_PROPERTY: '发送email联系',
        Company_Address: '公司地址'
    },
    News: {
        Title: '新闻',
        LATEST_NEWS: '最新资讯',
        Everything: '从市场动态到我们如何保证你的安全。',
        READ_ARTICLE: '阅读详情'
    },
    More: '更多房源',
    SIMILAR_PROPERTIES: '相似房源',
    login_in: '登录',
    register_in: '注册',
    cancel: '取消',
    GET_MORTGAGE_ADVICE: '获取贷款咨询',
    email: '邮箱',
    password: '密码',
    password1: '重复密码',
    tel: '电话',
    See_full_property_details:'查看完整的属性详情',
    ...zhLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
// 导出
export default cn;
