// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
    typetab: 'HOME',
    userinfo: {},
    token:'',
    searchform:{},
    formInline:{},
    lang:''
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    updatelang(state, newValue) {
      state.lang = newValue // 更新变量的方法
    },
    updatetypetab(state, newValue) {
      state.typetab = newValue // 更新变量的方法
    },
    updateuserinfo(state, newValue) {
      state.userinfo = newValue // 更新变量的方法
    },
    updatetoken(state, newValue) {
      state.token = newValue // 更新变量的方法
    },
    updatesearchform(state, newValue) {
      state.searchform = newValue // 更新变量的方法
    },
    updateformInline(state, newValue) {
      state.formInline = newValue // 更新变量的方法
    },
  },
  actions: {
    increment({ commit }) {
      commit('increment');
    }
  },
  getters: {
    count: state => state.count,
    typetab: state => state.typetab,
    userinfo: state => state.userinfo,
    lang: state => state.lang,
    token: state => state.token,
    searchform: state => state.searchform,
    formInline: state => state.formInline
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      // 存储的 key 的key值
      key: 'store',
      reducer(state) { // render错误修改
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state }
      }
    })
  ]

});