<!--  -->
<template>
    <div class="tabtop">
        <img src="../static/index/tabbg1.png" class="tabbg" alt="" v-if='type == 1'>
        <img src="../static/index/tabbg2.png" class="tabbg" alt="" v-if='type == 2'>
        <img src="../static/index/tabbg3.png" class="tabbg" alt="" v-if='type == 3'>
        <img src="../static/index/tabbg4.png" class="tabbg" alt="" v-if='type == 4'>
        <div class="searchbox">
            <div class="top_title">
                <!-- <div> Unbound AI</div> -->
                <div> {{ top_title }}</div>
            </div>
            <div class="top_title1">{{ top_title1 }}</div>
            <div class="togo flex1" v-if="buttonshow" @click="togo">
                LEARN MORE
                <i class="el-icon-right ml10"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        buttonshow: Boolean,
        top_title: String,
        top_title1: String,
        image: String,
        type: String

    },
    data() {
        return {

        }
    },
    methods: {
        togo() {
            this.$emit('TabEvent', this.$t('Tab')[2])
            this.$store.commit('updatetypetab', this.$t('Tab')[2])
        }
    }
}

</script>

<style scoped>
.tabtop {
    position: relative;
    height: 700px;
}

.tabbg {
    width: 100%;
    height: 700px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
}

.searchbox {
    width: 100%;
    text-align: center;
    margin: 170px auto;
    /* background: rgba(0, 0, 0, 0.85); */
    /* margin: 0 auto; */

    /* margin-top: -230px; */
    position: absolute;

}

.searchbox1 {
    margin: 44px auto;
    width: 100%;
    text-align: center;
}

.top_title {
    width: 100%;
    margin: 44px auto;
    margin-top: 10px;
    text-align: center;
    height: 93px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 70px;
    color: #26D0FF;
    line-height: 93px;

}

.top_title1 {
    width: 100%;
    margin: 44px auto;
    /* width: 989px; */
    height: 70px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 70px;

}

.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}
</style>
