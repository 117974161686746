<!--  -->
<template>
    <div>
        <div class="servicesbox">
            <div class="seve_top">
                Main Services
            </div>
            <div class="seve_top1">
                Unbound AI offers the following core services
            </div>
            <div class="seve_boxs">
                <div class="seve_box flex1" v-for="(item, index) in list" :key="index">
                    <div class="seve_box_l" v-if="index == 0">
                        <img src="../static/index/s1.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index == 1">
                        <img src="../static/index/s2.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index == 2">
                        <img src="../static/index/s3.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_l" v-if="index == 3">
                        <img src="../static/index/s4.png" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div class="seve_box_r">
                        <div class="r_title1">
                            {{ item.title }}
                        </div>
                        <div class="r_title2">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img src="../static/index/s1.png" alt=""> -->

            <div class="togo flex1" @click="togo">
                LEARN MORE
                <i class="el-icon-right ml10"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                icon: '../static/index/s1.png',
                title: 'AI Algorithm Development',
                content: 'We provide customized AI algorithm development for enterprises and research institutions'
            }, {
                icon: '../static/index/s2.png',
                title: 'Data Science Consulting',
                content: 'We provide customized data science consulting services for enterprises and research institutions'
            }, {
                icon: '../static/index/s3.png',
                title: 'Smart Manufacturing and Industrial IoT Solutions',
                content: 'We provide customized AI algorithm development and data science consulting services for enterprises and research institutions'
            }, {
                icon: '../static/index/s4.png',
                title: 'Scientific and Industrial Software Integration',
                content: 'We specialize in integrating advanced scientific and industrial software (such as LAMMPS, COMSOL, Abaqus, etc.) with AI technology'
            }]
        }
    },
    methods: {
        togo() {
            this.$emit('TabEvent', this.$t('Tab')[1])
            this.$store.commit('updatetypetab', this.$t('Tab')[1])
        }
    }
}

</script>

<style scoped>
.servicesbox {
    /* padding: 90px 380px; */
    text-align: center;
    background: #101112;
    padding-bottom: 30px;
}

.seve_top {
    /* width: 257px; */
    margin-top: 80px;
    margin-bottom: 23px;
    height: 53px;
    font-family: Futura, Futura;
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 53px;

}

.seve_top1 {
    /* width: 257px; */
    /* margin-top: 80px; */
    margin-bottom: 23px;
    /* width: 320px; */
    height: 16px;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #9E9E9E;
    line-height: 16px;


}

.togo {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    line-height: 60px;
    text-align: center;
    width: 240px;
    justify-content: center;
    height: 60px;
    background: #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    border-radius: 20px;

}

.togo:hover {
    cursor: pointer;
    align-items: center;
    margin: 44px auto;
    margin-top: 100px;
    width: 240px;
    height: 60px;
    background: linear-gradient(180deg, #26D0FF 0%, #4167FF 100%), #000101;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
    line-height: 20px;
}

.seve_boxs {
    width: 75%;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin: 20px auto;

}

.seve_box {
    cursor: pointer;
    /* width: 580px; */
    /* height: 172px; */
    border-radius: 10px;
    background: #1D2023;
    padding: 32px;
}
.seve_box:hover {
    cursor: pointer;
    border: 2px solid rgba(38.0000015348196, 208.0000028014183, 255, 0.8500000238418579);

    /* width: 580px; */
    /* height: 172px; */
    border-radius: 10px;
    background: #1D2023;
    padding: 32px;
}
.seve_box_l {
    width: 80px;
    height: 80px;
    line-height: 100px;
    margin-right: 25px;
    background: rgba(38, 208, 255, 0.1);
    border-radius: 8px 8px 8px 8px;

}

.seve_box_r {
    width: 80%;

}

.r_title1 {

    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: left;
}

.r_title2 {
margin-top: 10px;
    font-family: Helvetica, Helvetica;
    font-weight: 400;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 24px;
    text-align: left;


}
</style>
